import React from "react"
import Section from "../Section"
import Col from 'react-bootstrap/Col';
import { StaticQuery, graphql } from "gatsby";
import Teaser from "../../components/Teaser";
import Feature from "../../components/Feature";
import Headline from "../Headline";
import Button from "react-bootstrap/Button"
import { getFeaturedItem, getFeaturedItems } from "../../services/utility"
import { Row, Alert } from "react-bootstrap";

export default class LeaderPreview extends React.Component {
  render() {
    let showTalks = false;
    return (
      <StaticQuery query={query} render={data => (
        <div>
          {getFeaturedItem(data.top) && <Section className="primary" useContainer={false}>
            <Feature content={getFeaturedItem(data.top)} centerVertically={true} isBonus={true} />
          </Section> } 
          {getFeaturedItems(data.recent) && <Section className="white" useRow={false}>
            <Row className="justify-content-center faded">
              <Col xs={12}><Headline>What's New</Headline></Col>
              {getFeaturedItems(data.recent).map((recentItem) => {
                return (
                  <Col md={4} key={"recent-" + recentItem.slug}>
                    <Teaser content={recentItem} useLink={false} isBonus={true} />
                  </Col>
                );
              })}
            </Row>
            <Alert variant="info" className="text-center pt-4 section-overlay">
              <Alert.Heading>Register to access content <br/>especially for leaders!</Alert.Heading>
              <p className="py-2">
                <Button href="/leaders/register" variant="primary" className="mr-3 px-4 font-weight-bold">Register</Button>
                <Button href="/leaders/signin" variant="primary" className="ml-3 px-4 font-weight-bold">Sign In</Button>
              </p>
            </Alert>
          </Section> }
          {getFeaturedItems(data.talks) && showTalks && <Section className="tertiary" useRow={false}>
            <Row className="justify-content-center faded">
              <Col xs={12}>
                <Headline>Warrior Generation</Headline>
                <p>Practical help for those who are leading the next generation of Native young people closer to Jesus.</p>
              </Col>
              {getFeaturedItems(data.talks).map((talk) => {
                return (
                  <Col md={4} key={"talk-" + talk.slug}>
                    <Teaser content={talk} useLink={false} isBonus={true} />
                  </Col>
                );
              })}
            </Row>
            <Alert variant="info" className="text-center pt-4 section-overlay">
              <Alert.Heading>Register to access content <br/>especially for leaders!</Alert.Heading>
              <p className="py-2">
                <Button href="/leaders/register" variant="primary" className="mr-3 px-4 font-weight-bold">Register</Button>
                <Button href="/leaders/signin" variant="primary" className="ml-3 px-4 font-weight-bold">Sign In</Button>
              </p>
            </Alert>            
          </Section> }
          {getFeaturedItems(data.bonus) && <Section className="secondary" useCol={false}>
            <Row className="justify-content-center faded">
              <Col xs={12}><Headline>Bonus Video Content</Headline></Col>
              {getFeaturedItems(data.bonus).map((vidItem) => {
                return (
                  <Col md={4} key={"bonus-" + vidItem.slug}>
                    <Teaser content={vidItem} useLink={false} isBonus={true} />
                  </Col>
                );
              })}
              </Row>
            <Alert variant="info" className="text-center pt-4 section-overlay">
              <Alert.Heading>Register to access content <br/>especially for leaders!</Alert.Heading>
              <p className="py-2">
                <Button href="/leaders/register" variant="primary" className="mr-3 px-4 font-weight-bold">Register</Button>
                <Button href="/leaders/signin" variant="primary" className="ml-3 px-4 font-weight-bold">Sign In</Button>
              </p>
            </Alert>
          </Section> }
        </div>
      )} />
    );
  }
}

export const query = graphql`
  query {
    top: allContentfulFeatured(filter: {siteSection: {eq: "Leaders - Preview"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    recent: allContentfulFeatured(filter: {siteSection: {eq: "Leaders - Recent"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    talks: allContentfulFeatured(filter: {siteSection: {eq: "Leaders - Talks"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    bonus: allContentfulFeatured(filter: {siteSection: {eq: "Leaders - Bonus"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
  }
`