import React from "react"
import Section from "../Section"
import Col from 'react-bootstrap/Col';
import { StaticQuery, graphql } from "gatsby";
import Teaser from "../../components/Teaser";
import Feature from "../../components/Feature";
import Headline from "../Headline";
import Button from "react-bootstrap/Button"
import { getFeaturedItem, getFeaturedItems, loadScriptPromise } from "../../services/utility"

export default class LeaderContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      embedElement: null
    }
  }

  loadVideoAskEmbed() {
    if(typeof window !== 'undefined' && typeof window.videoask !== 'undefined' && this.state.embedElement === null) {
      window.videoask.loadEmbed({
        "kind": "widget",
        "url": "https://www.videoask.com/f074ekcm0",
        "options": {
          "widgetType": "VideoThumbnailExtraLarge",
          "text": "Questions?",
          "backgroundColor": "#359cc7",
          "position": "bottom-right"
        }
      }).then(element => {
        this.setState({
          embedElement: element
        })
      }).catch(err => {console.log(err)});
    }
  }

  componentDidMount() {
//    if(typeof window !== 'undefined' && typeof window.videoask === 'undefined') {
//      loadScriptPromise("https://www.videoask.com/embed/embed.js", "videoask").then(() => this.loadVideoAskEmbed()).catch(err => {console.log(err)});
//    } else {
//      this.loadVideoAskEmbed();
//    }
  }

  componentWillUnmount() {
    if(this.state.embedElement !== null) {
      this.state.embedElement.remove();
    }
  }

  render() {
    let showTalks = false;
    return (
      <StaticQuery query={query} render={data => ( 
        <div>
          {getFeaturedItem(data.top) && <Section className="primary" useContainer={false}>
            <Feature content={getFeaturedItem(data.top)} centerVertically={true} isBonus={true} />
          </Section> } 
          {getFeaturedItems(data.recent) && <Section className="white" useCol={false}>
            <Col xs={12}><Headline>What's New</Headline></Col>
            {getFeaturedItems(data.recent).map((recentItem) => {
              return (
                <Col md={4} key={"recent-" + recentItem.slug}>
                  <Teaser content={recentItem} isBonus={true} />
                </Col>
              );
            })}
          </Section> }
          {getFeaturedItems(data.talks) && showTalks && <Section className="tertiary" useCol={false}>
            <Col xs={12}>
              <Headline>Warrior Generation</Headline>
              <p>Practical help for those who are leading the next generation of Native young people closer to Jesus.</p>
            </Col>
            {getFeaturedItems(data.talks).map((talk) => {
              return (
                <Col md={4} key={"talk-" + talk.slug}>
                  <Teaser content={talk} isBonus={true} />
                </Col>
              );
            })}
          </Section> }
          {getFeaturedItems(data.bonus) && <Section className="secondary" useCol={false}>
            <Col xs={12}><Headline>Bonus Video Content</Headline></Col>
            {getFeaturedItems(data.bonus).map((vidItem) => {
              return (
                <Col md={4} key={"bonus-" + vidItem.slug}>
                  <Teaser content={vidItem} isBonus={true} />
                </Col>
              );
            })}
            <Col xs={12} className="text-center"><Button href="/resources/bonus" variant="primary" className="px-4 font-weight-bold">SHOW ALL</Button></Col>
          </Section> }
        </div>
      )} />
    );
  }
}

export const query = graphql`
  query {
    top: allContentfulFeatured(filter: {siteSection: {eq: "Leaders - Top"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    recent: allContentfulFeatured(filter: {siteSection: {eq: "Leaders - Recent"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    talks: allContentfulFeatured(filter: {siteSection: {eq: "Leaders - Talks"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    bonus: allContentfulFeatured(filter: {siteSection: {eq: "Leaders - Bonus"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
  }
`