import React from "react"
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react'
import Section from "../Section"
import Headline from "../Headline"

export default class Register extends React.Component {
  render() {
    return ( 
      <div>
        <Section className="secondary" centerHorizontally={true}>
          <Headline center={true}>Especially for Leaders</Headline>
          <p className="text-center">
            Register or Sign In to access content especially for leaders!
          </p>
          <div style={{ position: "relative" }}>
            <AmplifyAuthenticator initialAuthState={this.props.initialAuthState} usernameAlias="email">
              <AmplifySignUp slot="sign-up" usernameAlias="email"  submitButtonText="Register"
                formFields={[
                  {
                    type: "given_name",
                    label: "First Name *",
                    placeholder: "First Name",
                    required: true
                  },
                  {
                    type: "family_name",
                    label: "Last Name *",
                    placeholder: "Last Name",
                    required: true,
                  },
                  {
                    type: "email",
                    label: "Email *",
                    placeholder: "Email",
                    required: true,
                  },
                  {
                    type: "password",
                    label: "Password *",
                    placeholder: "Password",
                    required: true,
                  },
                  {
                    type: "phone_number",
                    label: "Phone Number",
                    placeholder: "Phone Number (Optional)",
                    required: false,
                  },
                  {
                    type: "custom:mailing_street",
                    label: "Mailing Address *",
                    placeholder: "Street",
                  },
                  {
                    type: "custom:mailing_city",
                    label: "City *",
                    placeholder: "City"
                  },
                  {
                    type: "custom:mailing_state",
                    label: "State/Province *",
                    placeholder: "State/Province"
                  },
                  {
                    type: "custom:mailing_zip",
                    label: "Postal Code *",
                    placeholder: "Postal Code",
                  },                  
                  {
                    type: "custom:serving_area",
                    label: "Native community you are serving *",
                    placeholder: "Native community you are serving"
                  }
                ]} 
                foo
              />
              <AmplifySignIn slot="sign-in" usernameAlias="email"
                formFields={[
                  {
                    type: "email",
                    label: "Email Address",
                    placeholder: "Enter your email address",
                  },
                  {
                    type: "password",
                    label: "Password",
                    placeholder: "Password",
                    required: true,
                  }
                ]}               
              />
            </AmplifyAuthenticator>
          </div>
        </Section>

      </div>
    );
  }
}
