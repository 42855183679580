import React from 'react'
import { Redirect } from '@reach/router'
import { AuthState } from '@aws-amplify/ui-components'

export default class PrivateRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {}

  render() {
    if(this.props.authState!==AuthState.Loading && this.props.authState!==AuthState.SignedIn){
      return <Redirect to="/leaders/preview" noThrow />
    }
    const { component: Component, location, ...rest } = this.props
    return (
      <Component {...rest} />
    )
  }
}
