import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames";
import VimeoEmbed from "../components/VimeoEmbed"
import { getLinkForContent } from "../services/utility";

export default class Teaser extends React.Component {
  render() {
    let { name, vimeoId, cinematicAspectRatio, vimeoThumbnail, playInline, image, description } = this.props.content;
    let link = getLinkForContent(this.props.content, this.props.isBonus);
    let useVimeoEmbed = (playInline === true && vimeoId);
    let useLink = ((typeof this.props.useLink === 'undefined' && useVimeoEmbed === false) || this.props.useLink === true);
    return (
      <div className={classNames("teaser", this.props.className)}>
        { useVimeoEmbed && <VimeoEmbed vimeoId={vimeoId} useCinema={cinematicAspectRatio}></VimeoEmbed> }
        { !useVimeoEmbed && useLink && <Link to={link}>
          { vimeoThumbnail && <GatsbyImage image={getImage(vimeoThumbnail)} alt={name} />}
          { !vimeoThumbnail && image && <GatsbyImage image={getImage(image)} alt={name} />}
          </Link> }
        { !useVimeoEmbed && !useLink && image && <GatsbyImage image={getImage(image)} alt={name} /> }
        { !useVimeoEmbed && !useLink && vimeoThumbnail && <GatsbyImage image={getImage(vimeoThumbnail)} alt={name} /> }
        <div className="teaserDescription pt-2">
          { useVimeoEmbed && <h3>{name}</h3> }
          { !useVimeoEmbed && useLink && <h3><Link to={link}>{name}</Link></h3> }
          { !useVimeoEmbed && !useLink && <h3>{name}</h3> }
          { (typeof this.props.showDescription === 'undefined' || this.props.showDescription === true) 
              && description && description.childMarkdownRemark && <div dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
            }}
          />}
        </div>
      </div>
    )
  }
}

Teaser.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string,
  useLink: PropTypes.bool,
  showDescription: PropTypes.bool,
  isBonus: PropTypes.bool
}

export const pdfFragment = graphql`
  fragment PDFResource on ContentfulPdfResource {
    name
    description {
      childMarkdownRemark {
        html
      }
    }
    image {
      title
      gatsbyImageData(
        layout: CONSTRAINED
        width: 1200
        )
    }
    file {
      title
      file {
        url
      }  
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`

export const teaserFragment = graphql`
  fragment Teaser on ContentfulMinistryEventContentfulPdfResourceContentfulTopicContentfulVideoResourceContentfulWarriorWorshipSongUnion {
      ...MinistryEvent
      ...Topic
      ...VideoResource
      ...PDFResource
      ...WarriorWorshipSong
    }
`
