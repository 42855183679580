import React from "react"
import { Router } from "@reach/router"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PrivateRoute from "../../components/PrivateRoute";
import PublicRoute from "../../components/PublicRoute";
import LeaderContent from "../../components/secure/LeaderContent";
import LeaderPreview from "../../components/secure/LeaderPreview";
import Register from "../../components/secure/Register";
import { AuthState } from '@aws-amplify/ui-components'
import { getCurrentAuthState } from '../../services/utility'
import Account from "../../components/secure/Account";

const NotFound = () => {
  if (typeof window !== 'undefined') {
    window.location.href = '/leaders';
  }
  return null;
}

export default class Leaders extends React.Component {
  constructor(props) {
    super(props)
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.state = { 
      authState: getCurrentAuthState()
    }
  }

  onAuthStateChanged = event => {
    this.setState({
      authState: getCurrentAuthState()
    });
  }
  
  render() {
    return ( 
      <Layout onAuthStateChanged={this.onAuthStateChanged}>
        <Seo title="Leaders" />
        <Router basepath="/leaders">
          <PublicRoute path="/preview" authState={this.state.authState} component={LeaderPreview} />
          <PublicRoute path="/register" authState={this.state.authState} component={Register} initialAuthState={AuthState.SignUp} />
          <PublicRoute path="/signin" authState={this.state.authState} component={Register} initialAuthState={AuthState.SignIn} />
          <PrivateRoute path="/" authState={this.state.authState} component={LeaderContent} />
          <PrivateRoute path="/account" authState={this.state.authState} component={Account} />
          <NotFound default />
        </Router>
      </Layout>
    );
  }
}
