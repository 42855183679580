import React from "react"
import { AmplifySignOut } from '@aws-amplify/ui-react'
import Section from "../Section"
import Headline from "../Headline"

export default class Account extends React.Component {
  render() {
    return ( 
      <div>
        <Section className="secondary" centerHorizontally={true}>
          <Headline center={true}>Account</Headline>
          <div style={{ position: "relative" }}>
            <AmplifySignOut />
          </div>
        </Section>
      </div>
    );
  }
}
